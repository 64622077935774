import React, { useState } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import Layout from "../components/layout"
import Modal from "../components/common/modal"
import SEO from "../components/seo"

const Albums = ({ name, cover, onClick }) => (
  <div className="activity__album" onClick={onClick}>
    <img alt="" src={cover} />
    <div>{name}</div>
  </div>
)

const AlbumDetail = ({ activities, onBack }) => {
  const [modalVisible, setModalVisibility] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const indexMap = {}
  const images = []
  activities.forEach((activiy, index) => {
    indexMap[activiy.image.publicURL] = index
    images.push({ src: activiy.image.publicURL, caption: activiy.title })
  })
  return (
    <div>
      <button onClick={onBack}>回上一頁</button>
      <br />
      <hr />
      <br />
      <div className="activity__thumbnails">
        {activities.map((activiy, index) => (
          <div
            key={index}
            className="activity__thumbnail"
            onClick={() => {
              setModalVisibility(true)
              setCurrentIndex(indexMap[activiy.image.publicURL])
            }}
          >
            <img alt="" src={activiy.image.publicURL} />
            <div>{activiy.title}</div>
          </div>
        ))}
      </div>
      <Modal
        reveal={modalVisible}
        size="half"
        textCancel=""
        noShadow={true}
        clickAway={true}
        onCancel={() => setModalVisibility(false)}
      >
        <Carousel
          showThumbs={false}
          useKeyboardArrows={true}
          infiniteLoop={true}
          selectedItem={currentIndex}
        >
          {images.map(image => (
            <div key={image.src}>
              <img src={image.src} />
              <p className="legend">{image.caption}</p>
            </div>
          ))}
        </Carousel>
      </Modal>
    </div>
  )
}

const Activity = ({ data }) => {
  const [album, setAlbum] = useState("")

  const albumMap = {}
  data.allStrapiActivity.edges.forEach(({ node }) => {
    if (node.album in albumMap) {
      albumMap[node.album].activities.push(node)
      if (node.cover) albumMap[node.album].cover = node.image.publicURL
    } else {
      albumMap[node.album] = {
        activities: [node],
        cover: node.cover ? node.image.publicURL : "",
      }
    }
  })

  return (
    <Layout>
      <SEO title="Activity" />
      {album ? (
        <AlbumDetail
          activities={albumMap[album].activities}
          onBack={() => setAlbum("")}
        />
      ) : (
        <div className="activity__albums">
          {Object.keys(albumMap).map((k, i) => (
            <Albums
              name={k}
              key={i}
              cover={albumMap[k].cover}
              onClick={() => setAlbum(k)}
            />
          ))}
        </div>
      )}
    </Layout>
  )
}

export default Activity

export const query = graphql`
  query {
    allStrapiActivity(sort: { fields: [strapiId], order: [DESC] }) {
      edges {
        node {
          strapiId
          album
          title
          cover
          image {
            publicURL
          }
        }
      }
    }
  }
`
